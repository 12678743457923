import React from "react";
import {
  SEO,
  PageLayout,
  HeroElement,
  Content,
  ReservationForm,
} from "@bluefin/components";
import { Grid, Image } from "semantic-ui-react";
import { graphql } from "gatsby";

import WebsiteLayout from "../components/WebsiteLayout.jsx";
import { getComponentFiles, getPageHeroImage } from "../utils/utils";

export default class ReservationsPage extends React.PureComponent {
  render() {
    const { data } = this.props;
    const {
      fishermanBusiness,
      fishermanBusinessWebsitePage,
      allFishermanBusinessLocation,
    } = data;

    return (
      <WebsiteLayout>
        <SEO
          businessName={fishermanBusiness.name}
          pageName={fishermanBusinessWebsitePage.title}
          images={getComponentFiles({
            components: fishermanBusinessWebsitePage.components,
            componentName: "HeroElement",
            numToSelect: 1,
          })}
        />
        <PageLayout
          hero={
            <HeroElement
              header={"Reservations"}
              tagline={""}
              images={getPageHeroImage({
                options: fishermanBusinessWebsitePage.components,
              })}
              ctaInverted={true}
              ctaColor={"white"}
              containerColor={"primary"}
              textColor={"white"}
              showMultiple={true}
              overlay={true}
              fullWidth={true}
              parallax={false}
              containerAs={"none"}
              height={500}
              gutter={false}
            />
          }
          subfooter={false}
        >
          <Grid
            stackable={true}
            className={"component-section-container"}
            verticalAlign={"middle"}
            textAlign={"center"}
          >
            <Grid.Column width={16}>
              <Content container={true}>
                <Content.Markup isMarkdown={false}>
                  <p>
                    We have indoor and outdoor dining available. Please indicate
                    your preference when making an online reservation.
                  </p>
                  <p>
                    If you want to make a reservation for the same day after
                    4:00 p.m, please call the restaurant directly for
                    availability.
                  </p>
                  <div>(718) 758 5488 / (718) 758 5499</div>
                  <Image
                    src={
                      "https://fisherman.gumlet.io/public/lachacra/logos_la_chacra__PERUVIAN_KITCHEN_PNG_1.png.png"
                    }
                    centered={true}
                    size={"small"}
                  />
                </Content.Markup>
              </Content>
            </Grid.Column>
          </Grid>
          <Grid
            stackable={true}
            textAlign={"center"}
            style={{ paddingTop: "3rem", paddingBottom: "3rem" }}
          >
            <Grid.Column width={10} textAlign={"left"}>
              <ReservationForm
                destinationEmail={"reservationschacra1@gmail.com"}
                business={{
                  id: fishermanBusiness._id,
                  type: fishermanBusiness.type,
                }}
                locations={allFishermanBusinessLocation.nodes}
              />
            </Grid.Column>
          </Grid>
        </PageLayout>
      </WebsiteLayout>
    );
  }
}

export const query = graphql`
  query {
    fishermanBusiness {
      name
      _id
      type
    }
    fishermanBusinessWebsitePage(
      pageType: { eq: "Reservations" }
      title: { eq: "Reservations" }
    ) {
      title
      components {
        fastId
        componentIdentifier
        order
        files {
          file
          altText
          gatsbyFile {
            childImageSharp {
              gatsbyImageData(layout: FULL_WIDTH)
            }
          }
        }
      }
    }
    allFishermanBusinessLocation {
      nodes {
        _id
        street
        city
        name
        phoneNumber
        timezone
        hours {
          open
          close
          day
        }
        primaryLocation
      }
    }
  }
`;
